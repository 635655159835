define("discourse/plugins/waze-semi-category/discourse/connectors/category-custom-settings/waze-semi-category-settings", ["exports", "@glimmer/component", "@ember/object", "I18n"], function (_exports, _component, _object, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global WAZE_SEMI_CATEGORIES */

  class WazeSemiCategorySettings extends _component.default {
    get category() {
      return this.args.outletArgs.category;
    }
    get semiCategories() {
      return WAZE_SEMI_CATEGORIES.map(_ref => {
        let {
          id
        } = _ref;
        return {
          id,
          name: _I18n.default.t(`waze.semi_categories.names.${id}`)
        };
      });
    }
    resetSemiCategory() {
      this.category.set(`custom_fields.waze_semi_category_id`, "");
    }
    static #_ = (() => dt7948.n(this.prototype, "resetSemiCategory", [_object.action]))();
  }
  _exports.default = WazeSemiCategorySettings;
});