define("discourse/plugins/waze-semi-category/discourse/components/waze-category-box", ["exports", "@ember/component", "@glimmer/component", "@ember/template", "@ember/template-factory"], function (_exports, _component, _component2, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <a
    href={{@category.url}}
    class="waze-semi-category-dialog-content-category"
    data-category-id={{@category.id}}
    data-notification-level={{@category.notificationLevelString}}
    data-url={{@category.url}}
  >
    <div class="waze-semi-category-dialog-content-category-logo">
      {{#if @category.uploaded_logo.url}}
        <img src={{@category.uploaded_logo.url}} />
      {{else}}
        <div
          class="waze-semi-category-dialog-content-category-abbreviation"
          style={{this.style}}
        >
          {{this.getAbbreviation}}
        </div>
      {{/if}}
    </div>
    <wz-body2 class="waze-semi-category-dialog-content-category-name">
      {{@category.name}}
    </wz-body2>
  </a>
  */
  {
    "id": "u1j+bJKM",
    "block": "[[[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"waze-semi-category-dialog-content-category\"],[15,\"data-category-id\",[30,1,[\"id\"]]],[15,\"data-notification-level\",[30,1,[\"notificationLevelString\"]]],[15,\"data-url\",[30,1,[\"url\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"waze-semi-category-dialog-content-category-logo\"],[12],[1,\"\\n\"],[41,[30,1,[\"uploaded_logo\",\"url\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,1,[\"uploaded_logo\",\"url\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"waze-semi-category-dialog-content-category-abbreviation\"],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"getAbbreviation\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,\"wz-body2\"],[14,0,\"waze-semi-category-dialog-content-category-name\"],[12],[1,\"\\n    \"],[1,[30,1,[\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@category\"],false,[\"if\"]]",
    "moduleName": "discourse/plugins/waze-semi-category/discourse/components/waze-category-box.hbs",
    "isStrictMode": false
  });
  class WazeCategoryBox extends _component2.default {
    get style() {
      return (0, _template.htmlSafe)(`background-color: #${this.args.category.color}`);
    }

    // Referenced from https://github.com/discourse/discourse-minimal-category-boxes/blob/bfc94b02e49eb648e4e0a91bcafc58bae9bfbac1/javascripts/discourse/components/category-box.js
    get getAbbreviation() {
      let abbr = this.args.category.name.toLocaleLowerCase().replace(/("|'|`|&|#|\$|\+|-|=|\(|\)|\/|\*)/g, "").replace(/(_|-| and | of | for |:)/g, " ").split(" ").filter(part => part.length);
      if (abbr.length > 1) {
        abbr = abbr[0].charAt(0).toLocaleUpperCase() + abbr[1].charAt(0).toLocaleUpperCase();
      } else {
        abbr = abbr[0].charAt(0).toLocaleUpperCase() + abbr[0].charAt(1).toLocaleUpperCase();
      }
      return abbr;
    }
  }
  _exports.default = WazeCategoryBox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeCategoryBox);
});