define("discourse/plugins/waze-semi-category/discourse/connectors/discovery-above/waze-semi-categories", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/template", "I18n"], function (_exports, _component, _object, _service, _tracking, _template, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global WAZE_SEMI_CATEGORY_OTHER_ID, WAZE_SEMI_CATEGORIES */

  const getNormalizedSemiCategories = function () {
    let relativeUrlRoot = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "/";
    return WAZE_SEMI_CATEGORIES.reduce((acc, semiCategory) => {
      acc[semiCategory.id] = {
        ...semiCategory,
        name: _I18n.default.t(`waze.semi_categories.names.${semiCategory.id}`),
        categories: [],
        image: `${relativeUrlRoot}${semiCategory.image}`
      };
      return acc;
    }, {});
  };
  class WazeSemiCategories extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialogSemiCategory", [_tracking.tracked], function () {
      return {};
    }))();
    #dialogSemiCategory = (() => (dt7948.i(this, "dialogSemiCategory"), void 0))();
    wazeSemiCategoriesDialogId = "waze-semi-categories-dialog";

    // The `category` object is always present for a category page, but it's `undefined` for the main page
    // where this component is rendered as well.
    get category() {
      return this.args.outletArgs.category;
    }
    get subcategories() {
      return this.category?.subcategories || [];
    }
    get shouldShowSemiCategories() {
      return this.category && this.subcategories.length > 0 && this.category.custom_fields.waze_show_semi_category_layout;
    }
    get normalizedSemiCategories() {
      const normalizedSemiCategories = getNormalizedSemiCategories(this.siteSettings.waze_semi_category_relative_url_root);
      this.subcategories.forEach(category => {
        const semiCategoryId = category.custom_fields.waze_semi_category_id;
        if (normalizedSemiCategories[semiCategoryId]) {
          normalizedSemiCategories[semiCategoryId].categories.push(category);
        } else {
          normalizedSemiCategories[WAZE_SEMI_CATEGORY_OTHER_ID].categories.push(category);
        }
      });
      return normalizedSemiCategories;
    }
    get availableSemiCategories() {
      return Object.values(this.normalizedSemiCategories).filter(semiCategory => semiCategory.categories.length > 0);
    }
    styleForSemiCategoryLogo(semiCategory) {
      const styleStringComponents = [];
      if (semiCategory.color) {
        styleStringComponents.push(`background-color: #${semiCategory.color}`);
      }
      return (0, _template.htmlSafe)(`${styleStringComponents.join(";")}`);
    }
    onSemiCategoryClick(semiCategory) {
      this.dialogSemiCategory = semiCategory;
      document.getElementById(this.wazeSemiCategoriesDialogId).showDialog();
    }
    static #_3 = (() => dt7948.n(this.prototype, "onSemiCategoryClick", [_object.action]))();
    onDialogClose() {
      document.getElementById(this.wazeSemiCategoriesDialogId).hideDialog();
    }
    static #_4 = (() => dt7948.n(this.prototype, "onDialogClose", [_object.action]))();
  }
  _exports.default = WazeSemiCategories;
});